<template>
  <div>
    <Header />
    <v-app>
      <ScrollToTop />
      <div class="about-wrapped">
        <v-container>
          <div class="about-header">
            <h1 class="title-about font-weight-black my-10">
              {{ $t("about_us") }}
            </h1>
          </div>
          <div class="about-body">
            <v-row>
              <v-col cols="6" class="highlight-text">
                <blockquote>
                  <h2 class="font-weight-black text-break" style="max-width: 90%">
                    &#8220;<span class="highlight">{{ $t("welcome_msg") }}</span>&#8221;
                  </h2>
                </blockquote>
              </v-col>
              <v-col cols="6" class="about-text">
                <p class="ma-0 text-justify">
                  {{ $t("about_us_1") }}, <strong>HARMET & CO</strong>
                  {{ $t("about_us_2") }}
                </p>
                <br />
                <p class="ma-0 text-justify">
                  <strong>HARMET & CO</strong> {{ $t("about_us_3") }}
                </p>
                <br />
                <p class="m-0 text-justify">
                  {{ $t("about_us_4") }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="vision">
                <v-card class="pa-5 vision-mission">
                  <h3 class="text-center">{{ $t("vision") }}</h3>
                  <p class="text-justify">
                    {{ $t("vision_line_1") }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="mission">
                <v-card class="pa-5 vision-mission">
                  <h3 class="text-center">{{ $t("mission") }}</h3>
                  <p class="text-justify">
                    {{ $t("mission_line_1") }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
            <!-- <v-row>
          <v-col cols="4" class="left-side font-weight-black"
            >OUR MISSION</v-col
          >
          <v-col cols="8" class="right-side"
            >Our reliable service in intellectual property will protect your
            ideas, the most valuable asset for your business growth. You only
            need to think and create, while the protection is our
            concern.</v-col
          >
        </v-row>
      </div>
      <div class="about-vision">
        <v-row>
          <v-col cols="4" class="left-side font-weight-black"
            >FIRM PROFILE</v-col
          >
          <v-col cols="8" class="right-side"
            >Establish in 2006, <strong>HARMET & CO</strong> is one of a
            prominent intellectual property firm in Indonesia. We are dedicated
            to providing comprehensive services in Intellectual Property Rights
            (IPR) for domestic and overseas clients. As a growing firm, we have
            a good reputation for our capabilities and professionalism.</v-col
          >
        </v-row>
      </div>
      <div class="about-value">
        <v-row>
          <v-col cols="4" class="left-side font-weight-black">OUR VISION</v-col>
          <v-col cols="8" class="right-side"
            >Your ideas is your most valuable asset in a global business
            community. Intellectual property right is all about respect and
            protecting ideas.</v-col
          >
        </v-row> -->
          </div>
        </v-container>
      </div>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2";
import Footer from "@/components/Footer";
import ScrollToTop from "../components/ScrollToTop.vue";

export default {
  name: "About",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
.vision-mission {
  height: 11em !important;
}

.highlight {
  background: linear-gradient(120deg, #c6933c 0%, #c6933c 100%);
  background-repeat: no-repeat;
  background-size: 100% 35%;
  background-position: 0 90%;
  line-height: 1.5;
}

.about-wrapped {
  margin-top: 6.5em;
  margin-bottom: 3.5em;
}

.about-header {
  /* width: 100vw; */
  letter-spacing: 10px;
}

.title-about {
  text-align: center;
}

.about-body {
  height: auto;
  /* background-color: #8c3d40; */
  /* color: #ffffff; */
}

/* < !-- .about-vision {
  background: url("~@/assets/about-vision-2.jpg") no-repeat;
  height: auto;
  padding: 5em;
  color: #ffffff;
  background-size: cover;
  background-color: #504e4ecf;
  background-blend-mode: soft-light;
}
 */
-->.about-value {
  background-color: #c6933c;
  height: auto;
  padding: 5em;
  color: white;
}

.theme--light.v-sheet[data-v-039c5b43] {
  height: auto;
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .vision-mission {
    height: auto !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .vision-mission {
    height: auto !important;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 10px 45px;
  }

  .highlight-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .highlight-text h2 {
    max-width: 100% !important;
  }

  .about-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .vision {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mission {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .vision-mission {
    height: auto !important;
  }
}
</style>
